import * as React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'

import Layout from '@components/layout'
import MustRead from '@components/must-read'

const ThankYouPage = () => {
  return (
    <Layout pageTitle="Thank You">
      <Container fluid="xxl">
        <Row>
          <Col>
            <div className="hero-banner large-hero light-hero">
              <StaticImage
                className="hero-image"
                src="../../../media/wessuc_default_hero.png"
                alt=""
                aspectRatio={3 / 1}
                layout="fullWidth" 
              />
              <div className="hero-text">
                <div className="section">
                  <h1>We can't wait to talk waste with you.</h1>
                  <h3>
                    <span>Thanks for requesting our quick 'n' dirty wastewater audit. We'll be in touch as soon as we can to schedule our visit to your facility. In the meantime, you might be interested in our article </span>
                    <Link to="/articles/23-questions-about-your-companys-waste-water-you-cant-ignore" className="bold-2">
                      23 questions about your company's wastewater you can't afford to ignore.
                    </Link>
                    <span> It's a great way to start thinking differently about your processes... and your wastewater</span>
                  </h3>
                  <Link to="/articles/23-questions-about-your-companys-waste-water-you-cant-ignore">
                    <Button variant="primary" className="shadow">LET'S PLAY 23 QUESTIONS</Button>
                  </Link>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <MustRead />
    </Layout>
  )
}

export default ThankYouPage